<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/temp/banner_ztzl.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${apiName}special?t=${index}`">{{item.name}}</a>
                <span></span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd listHdPage clearfloat">
              <div class="fl listHdName">{{hdTitle}}</div>
              <div class="fr listHdGrop"><a :href="`${apiName}`">首页</a> · <a :href="`${apiName}special`">{{slideHd.title}}</a> · <span class="txt">{{hdTitle}}</span> </div>
            </div>
            <div class="listContainer">
              <!-- <ul class="listList">
                <li v-for="(item, index) in listData" :key="index">
                  <a class="clearfloat" href="/detail">
                    <div class="time fr">
                      <h3>{{item.time.slice(5,10)}}</h3>
                      <p>{{item.time.slice(0,4)}}</p>
                    </div>
                    <div class="inner single-line">
                      {{item.title}}
                    </div>
                  </a>
                </li>
              </ul>
              <div class="listPage">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="currentChange">
                </el-pagination>
              </div> -->
              <detail-page ref="listPage" :gropId="gropId" v-if="pageType == 1"></detail-page>
              <list-page ref="listPage" :gropId="gropId" v-if="pageType == 2"></list-page>
              <list-img ref="listPage" :gropId="gropId" v-if="pageType == 3"></list-img>
              <list-video ref="listPage" :gropId="gropId" v-if="pageType == 4"></list-video>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import listPage from '@/components/list.vue'
import detailPage from '@/components/detail.vue'
import listImg from '@/components/listImg.vue'
import listVideo from '@/components/listVideo.vue'
export default {
  components: {
    listPage,
    detailPage,
    listImg,
    listVideo
  },
  name: 'list',
  data(){
    return {
      pageType: null,
      gropId: '',
      hdTitle: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '专题专栏',
        sub: 'SPECIAL',
      },
      // type 1: 文章；2：列表；3：图片列表；4：视频列表；
      slideList:[],
      pageSize: 10,
      pageNum: 1,
      total: 20,
      listData: []
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
      // _this.navNameShow = true
      // _this.name1 = _this.$route.query.navName;
      // _this.navNameUrl = _this.navNameList[_this.$route.query.navName]
    }else{
      _this.active = 0
    }

    _this.getSideNav()
    // _this.hdTitle = _this.slideList[_this.active].title
    // _this.pageCont = _this.page[_this.active]
  },
  methods: {
    currentChange(e){
      console.log('e :>> ', e);
    },
    // 获取二级栏目
    async getSideNav() {
      var _this = this;
      _this.slideList = []
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: "162"});
      data.data.length && data.data.forEach(line => {
        // type 1: 文章；2：列表；3：图片列表；4：视频列表；
        let dataJson = {
          name: line.name,
          id: line.id,
          type: 2
        }
        if (line.have_list == 1) {
          dataJson.type = 1
        }
        
        _this.slideList.push(dataJson)
      })
      // _this.slideList = data.data
      
      this.$nextTick(() => {
        _this.hdTitle = _this.slideList[_this.active].name
        _this.hdName = _this.slideList[_this.active].name
        _this.listId = _this.slideList[_this.active].id
        _this.gropId = _this.slideList[_this.active].id
        _this.pageType = _this.slideList[_this.active].type
      })
      // _this.getList()
    },
    // 查列表
    async getList() {
      var _this = this;
      
      _this.loading = true
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: _this.listId, page: _this.pageNum, pageSize: _this.pageSize});
      // console.log('data :>> ', data);
      _this.listData = data.data.list
      _this.total = data.data.total
      _this.loading = false
    },
  }
}
</script>